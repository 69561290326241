// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.feed__status {
  width: 90%;
  margin: 1rem auto;
}

.feed__status form {
  display: flex;
  align-items: center;
}

.feed__status form * {
  margin: 0 0.5rem;
}

.feed__control {
  text-align: center;
}

.new-post__preview-image {
  width: 15rem;
  height: 7rem;
}

@media (min-width: 768px) {
  .feed__status {
    width: 30rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Feed/Feed.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,YAAY;AACd;;AAEA;EACE;IACE,YAAY;EACd;AACF","sourcesContent":[".feed__status {\n  width: 90%;\n  margin: 1rem auto;\n}\n\n.feed__status form {\n  display: flex;\n  align-items: center;\n}\n\n.feed__status form * {\n  margin: 0 0.5rem;\n}\n\n.feed__control {\n  text-align: center;\n}\n\n.new-post__preview-image {\n  width: 15rem;\n  height: 7rem;\n}\n\n@media (min-width: 768px) {\n  .feed__status {\n    width: 30rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
