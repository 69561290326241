// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-nav {
  height: 100%;
  padding: 0 1rem;
  display: flex;
  align-items: center;
}

.spacer {
  flex: 1 1;
}

.main-nav__items {
  list-style: none;
  padding: 0;
  margin: 0 1.5rem;
  display: none;
}

@media (min-width: 768px) {
  .main-nav__items {
    display: flex;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Navigation/MainNavigation/MainNavigation.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,eAAe;EACf,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,SAAO;AACT;;AAEA;EACE,gBAAgB;EAChB,UAAU;EACV,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".main-nav {\n  height: 100%;\n  padding: 0 1rem;\n  display: flex;\n  align-items: center;\n}\n\n.spacer {\n  flex: 1;\n}\n\n.main-nav__items {\n  list-style: none;\n  padding: 0;\n  margin: 0 1.5rem;\n  display: none;\n}\n\n@media (min-width: 768px) {\n  .main-nav__items {\n    display: flex;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
