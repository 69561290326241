// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input {
  margin: 1rem 0;
  width: 100%;
}

.input label {
  display: block;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}

.input input,
.input textarea {
  display: block;
  font: inherit;
  padding: 0.25rem 0.5rem;
  width: 100%;
  border-radius: 3px;
  border: 1px solid #ccc;
}

.input .touched.invalid {
    border-color: red;
    background: #ffc2c2;
}

.input input:focus,
.input textarea:focus {
  outline: none;
  border-color: #3b0062;
  color: #3b0062;
}
`, "",{"version":3,"sources":["webpack://./src/components/Form/Input/Input.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,WAAW;AACb;;AAEA;EACE,cAAc;EACd,yBAAyB;EACzB,sBAAsB;AACxB;;AAEA;;EAEE,cAAc;EACd,aAAa;EACb,uBAAuB;EACvB,WAAW;EACX,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;;EAEE,aAAa;EACb,qBAAqB;EACrB,cAAc;AAChB","sourcesContent":[".input {\n  margin: 1rem 0;\n  width: 100%;\n}\n\n.input label {\n  display: block;\n  text-transform: uppercase;\n  margin-bottom: 0.25rem;\n}\n\n.input input,\n.input textarea {\n  display: block;\n  font: inherit;\n  padding: 0.25rem 0.5rem;\n  width: 100%;\n  border-radius: 3px;\n  border: 1px solid #ccc;\n}\n\n.input .touched.invalid {\n    border-color: red;\n    background: #ffc2c2;\n}\n\n.input input:focus,\n.input textarea:focus {\n  outline: none;\n  border-color: #3b0062;\n  color: #3b0062;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
