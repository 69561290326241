// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth-form {
    width: 90%;
    margin: auto;
    padding: 1rem;
    border: 1px solid #3b0062;
    border-radius: 5px;
  }
  
  @media (min-width: 768px) {
    .auth-form {
      width: 40rem;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Auth/Auth.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,kBAAkB;EACpB;;EAEA;IACE;MACE,YAAY;IACd;EACF","sourcesContent":[".auth-form {\n    width: 90%;\n    margin: auto;\n    padding: 1rem;\n    border: 1px solid #3b0062;\n    border-radius: 5px;\n  }\n  \n  @media (min-width: 768px) {\n    .auth-form {\n      width: 40rem;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
