// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backdrop {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
  transition: opacity 0.3s ease-out;
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/components/Backdrop/Backdrop.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,+BAA+B;EAC/B,YAAY;EACZ,eAAe;EACf,OAAO;EACP,MAAM;EACN,iCAAiC;EACjC,UAAU;AACZ","sourcesContent":[".backdrop {\n  width: 100%;\n  height: 100vh;\n  background: rgba(0, 0, 0, 0.75);\n  z-index: 100;\n  position: fixed;\n  left: 0;\n  top: 0;\n  transition: opacity 0.3s ease-out;\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
