// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.single-post {
  width: 90%;
  margin: auto;
  text-align: center;
  color: #3b0062;
}

.single-post h2 {
  font-size: 1rem;
  color: #464646;
  padding-bottom: 1rem;
  border-bottom: 2px solid #464646;
}

.single-post__image {
  height: 20rem;
  width: 20rem;
  margin: 1rem auto;
}

@media (min-width: 768px) {
  .single-post {
    width: 40rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Feed/SinglePost/SinglePost.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,YAAY;EACZ,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,oBAAoB;EACpB,gCAAgC;AAClC;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE;IACE,YAAY;EACd;AACF","sourcesContent":[".single-post {\n  width: 90%;\n  margin: auto;\n  text-align: center;\n  color: #3b0062;\n}\n\n.single-post h2 {\n  font-size: 1rem;\n  color: #464646;\n  padding-bottom: 1rem;\n  border-bottom: 2px solid #464646;\n}\n\n.single-post__image {\n  height: 20rem;\n  width: 20rem;\n  margin: 1rem auto;\n}\n\n@media (min-width: 768px) {\n  .single-post {\n    width: 40rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
