// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-header {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
}

.content {
  margin-top: 3.5rem;
  padding: 1rem;
}

@media (min-width: 768px) {
  .content {
    margin-top: 3.5rem;
    padding: 1rem 2rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Layout/Layout.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,eAAe;EACf,OAAO;EACP,MAAM;AACR;;AAEA;EACE,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE;IACE,kBAAkB;IAClB,kBAAkB;EACpB;AACF","sourcesContent":[".main-header {\n  width: 100%;\n  position: fixed;\n  left: 0;\n  top: 0;\n}\n\n.content {\n  margin-top: 3.5rem;\n  padding: 1rem;\n}\n\n@media (min-width: 768px) {\n  .content {\n    margin-top: 3.5rem;\n    padding: 1rem 2rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
