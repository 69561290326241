// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }
  .loader div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 51px;
    height: 51px;
    margin: 6px;
    border: 6px solid #3b0062;
    border-radius: 50%;
    animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #3b0062 transparent transparent transparent;
  }
  .loader div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .loader div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .loader div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Loader/Loader.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,WAAW;IACX,YAAY;EACd;EACA;IACE,sBAAsB;IACtB,cAAc;IACd,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,WAAW;IACX,yBAAyB;IACzB,kBAAkB;IAClB,4DAA4D;IAC5D,yDAAyD;EAC3D;EACA;IACE,uBAAuB;EACzB;EACA;IACE,sBAAsB;EACxB;EACA;IACE,uBAAuB;EACzB;EACA;IACE;MACE,uBAAuB;IACzB;IACA;MACE,yBAAyB;IAC3B;EACF","sourcesContent":[".loader {\n    display: inline-block;\n    position: relative;\n    width: 64px;\n    height: 64px;\n  }\n  .loader div {\n    box-sizing: border-box;\n    display: block;\n    position: absolute;\n    width: 51px;\n    height: 51px;\n    margin: 6px;\n    border: 6px solid #3b0062;\n    border-radius: 50%;\n    animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;\n    border-color: #3b0062 transparent transparent transparent;\n  }\n  .loader div:nth-child(1) {\n    animation-delay: -0.45s;\n  }\n  .loader div:nth-child(2) {\n    animation-delay: -0.3s;\n  }\n  .loader div:nth-child(3) {\n    animation-delay: -0.15s;\n  }\n  @keyframes loader {\n    0% {\n      transform: rotate(0deg);\n    }\n    100% {\n      transform: rotate(360deg);\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
