// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile-toggle {
  background: transparent;
  border: none;
  display: flex;
  width: 2.5rem;
  height: 80%;
  flex-direction: column;
  justify-content: space-evenly;
  cursor: pointer;
  padding: 0;
  margin-right: 1rem;
}

.mobile-toggle__bar {
  width: 2.5rem;
  height: 4px;
  background: white;
}

@media (min-width: 768px) {
    .mobile-toggle {
        display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Navigation/MobileToggle/MobileToggle.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,YAAY;EACZ,aAAa;EACb,aAAa;EACb,WAAW;EACX,sBAAsB;EACtB,6BAA6B;EAC7B,eAAe;EACf,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,iBAAiB;AACnB;;AAEA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":[".mobile-toggle {\n  background: transparent;\n  border: none;\n  display: flex;\n  width: 2.5rem;\n  height: 80%;\n  flex-direction: column;\n  justify-content: space-evenly;\n  cursor: pointer;\n  padding: 0;\n  margin-right: 1rem;\n}\n\n.mobile-toggle__bar {\n  width: 2.5rem;\n  height: 4px;\n  background: white;\n}\n\n@media (min-width: 768px) {\n    .mobile-toggle {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
