// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navigation-item {
  padding: 0 1.5rem;
}

.navigation-item a {
  text-decoration: none;
  color: white;
}

.navigation-item.mobile {
  font-size: 1.75rem;
  margin: 0.5rem 0;
}

.navigation-item.mobile a {
  color: #3b0062;
}

.navigation-item a:hover,
.navigation-item a:active,
.navigation-item a.active {
  color: #fab83f;
}

.navigation-item:last-of-type {
  padding-right: 0;
}

.navigation-item button {
  font: inherit;
  background: transparent;
  border: none;
  color: white;
  cursor: pointer;
  padding: 0;
}

.navigation-item button:hover,
.navigation-item button:active {
  color: #fab83f;
}
`, "",{"version":3,"sources":["webpack://./src/components/Navigation/NavigationItems/NavigationItems.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;EACrB,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;;AAEA;;;EAGE,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,UAAU;AACZ;;AAEA;;EAEE,cAAc;AAChB","sourcesContent":[".navigation-item {\n  padding: 0 1.5rem;\n}\n\n.navigation-item a {\n  text-decoration: none;\n  color: white;\n}\n\n.navigation-item.mobile {\n  font-size: 1.75rem;\n  margin: 0.5rem 0;\n}\n\n.navigation-item.mobile a {\n  color: #3b0062;\n}\n\n.navigation-item a:hover,\n.navigation-item a:active,\n.navigation-item a.active {\n  color: #fab83f;\n}\n\n.navigation-item:last-of-type {\n  padding-right: 0;\n}\n\n.navigation-item button {\n  font: inherit;\n  background: transparent;\n  border: none;\n  color: white;\n  cursor: pointer;\n  padding: 0;\n}\n\n.navigation-item button:hover,\n.navigation-item button:active {\n  color: #fab83f;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
