// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.paginator__controls {
  display: flex;
  justify-content: center;
}

.paginator__control {
  width: 5rem;
  padding: 0.25rem 0;
  margin: 0 1rem;
  border: 1px solid #3b0062;
  background: transparent;
  font: inherit;
  cursor: pointer;
  font-size: 1rem;
  color: #3b0062;
}

.paginator__control:hover,
.paginator__control:active {
  color: #fab83f;
  border-color: #fab83f;
}

.paginator__control:focus {
  outline: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/Paginator/Paginator.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,cAAc;EACd,yBAAyB;EACzB,uBAAuB;EACvB,aAAa;EACb,eAAe;EACf,eAAe;EACf,cAAc;AAChB;;AAEA;;EAEE,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".paginator__controls {\n  display: flex;\n  justify-content: center;\n}\n\n.paginator__control {\n  width: 5rem;\n  padding: 0.25rem 0;\n  margin: 0 1rem;\n  border: 1px solid #3b0062;\n  background: transparent;\n  font: inherit;\n  cursor: pointer;\n  font-size: 1rem;\n  color: #3b0062;\n}\n\n.paginator__control:hover,\n.paginator__control:active {\n  color: #fab83f;\n  border-color: #fab83f;\n}\n\n.paginator__control:focus {\n  outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
