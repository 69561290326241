// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile-nav {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 30rem;
  max-width: 90%;
  background: white;
  box-shadow: 1px 0 8px rbga(0, 0, 0, 0.26);
  transition: transform 0.3s ease-out;
  transform: translateX(-100%);
  z-index: 200;
  padding: 3rem 2rem;
}

.mobile-nav.open {
  transform: translateX(0);
}

.mobile-nav__items {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
}

.mobile-nav__items.mobile {
    flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./src/components/Navigation/MobileNavigation/MobileNavigation.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,aAAa;EACb,YAAY;EACZ,cAAc;EACd,iBAAiB;EACjB,yCAAyC;EACzC,mCAAmC;EACnC,4BAA4B;EAC5B,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,UAAU;IACV,SAAS;AACb;;AAEA;IACI,sBAAsB;AAC1B","sourcesContent":[".mobile-nav {\n  position: fixed;\n  top: 0;\n  left: 0;\n  height: 100vh;\n  width: 30rem;\n  max-width: 90%;\n  background: white;\n  box-shadow: 1px 0 8px rbga(0, 0, 0, 0.26);\n  transition: transform 0.3s ease-out;\n  transform: translateX(-100%);\n  z-index: 200;\n  padding: 3rem 2rem;\n}\n\n.mobile-nav.open {\n  transform: translateX(0);\n}\n\n.mobile-nav__items {\n    display: flex;\n    list-style: none;\n    padding: 0;\n    margin: 0;\n}\n\n.mobile-nav__items.mobile {\n    flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
