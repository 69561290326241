// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toolbar {
    width: 100%;
    height: 3.5rem;
    background: #3b0062;
}`, "",{"version":3,"sources":["webpack://./src/components/Toolbar/Toolbar.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,cAAc;IACd,mBAAmB;AACvB","sourcesContent":[".toolbar {\n    width: 100%;\n    height: 3.5rem;\n    background: #3b0062;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
