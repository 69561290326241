// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo {
    font-size: 1rem;
    font-weight: bold;
    border: 1px solid white;
    padding: 0.5rem;
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/Logo/Logo.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,iBAAiB;IACjB,uBAAuB;IACvB,eAAe;IACf,YAAY;AAChB","sourcesContent":[".logo {\n    font-size: 1rem;\n    font-weight: bold;\n    border: 1px solid white;\n    padding: 0.5rem;\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
