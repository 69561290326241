// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal {
  position: fixed;
  width: 90%;
  left: 5%;
  top: 20vh;
  background: white;
  border-radius: 5px;
  z-index: 200;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}

.modal__header {
  border-bottom: 2px solid #3b0062;
}

.modal__header h1 {
  font-size: 1.5rem;
  color: #3b0062;
  margin: 1rem;
}

.modal__content {
  padding: 1rem;
}

.modal__actions {
  padding: 1rem;
  text-align: right;
}

.modal__actions button {
  margin: 0 0.5rem;
}

@media (min-width: 768px) {
  .modal {
    width: 40rem;
    left: calc((100% - 40rem) / 2);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Modal/Modal.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,UAAU;EACV,QAAQ;EACR,SAAS;EACT,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;EACZ,yCAAyC;AAC3C;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE;IACE,YAAY;IACZ,8BAA8B;EAChC;AACF","sourcesContent":[".modal {\n  position: fixed;\n  width: 90%;\n  left: 5%;\n  top: 20vh;\n  background: white;\n  border-radius: 5px;\n  z-index: 200;\n  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);\n}\n\n.modal__header {\n  border-bottom: 2px solid #3b0062;\n}\n\n.modal__header h1 {\n  font-size: 1.5rem;\n  color: #3b0062;\n  margin: 1rem;\n}\n\n.modal__content {\n  padding: 1rem;\n}\n\n.modal__actions {\n  padding: 1rem;\n  text-align: right;\n}\n\n.modal__actions button {\n  margin: 0 0.5rem;\n}\n\n@media (min-width: 768px) {\n  .modal {\n    width: 40rem;\n    left: calc((100% - 40rem) / 2);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
