// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.post {
  margin: 1rem 0;
  border: 1px solid #3b0062;
  border-radius: 5px;
  padding: 0.5rem;
}

.post__meta {
  font-size: 1rem;
  color: #707070;
  margin: 0;
}

.post__title {
  font-size: 1.5rem;
  margin: 1rem 0;
  color: #3b0062;
}

.post__image {
  height: 15rem;
  width: 100%;
}

.post__actions {
  text-align: right;
}

@media (min-width: 768px) {
  .post {
    padding: 1rem;
    width: 40rem;
    margin-left: auto;
    margin-right: auto;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Feed/Post/Post.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,yBAAyB;EACzB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,SAAS;AACX;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,WAAW;AACb;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE;IACE,aAAa;IACb,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;EACpB;AACF","sourcesContent":[".post {\n  margin: 1rem 0;\n  border: 1px solid #3b0062;\n  border-radius: 5px;\n  padding: 0.5rem;\n}\n\n.post__meta {\n  font-size: 1rem;\n  color: #707070;\n  margin: 0;\n}\n\n.post__title {\n  font-size: 1.5rem;\n  margin: 1rem 0;\n  color: #3b0062;\n}\n\n.post__image {\n  height: 15rem;\n  width: 100%;\n}\n\n.post__actions {\n  text-align: right;\n}\n\n@media (min-width: 768px) {\n  .post {\n    padding: 1rem;\n    width: 40rem;\n    margin-left: auto;\n    margin-right: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
